// "use server";
import { bitrix } from "../../lib/util";

export const bitrixLeadAction = async (data, queryParams, sourceId) => {
  const leadData = JSON.stringify({
    fields: {
      TITLE: "learnerspoint.org",
      utm_source: queryParams?.UTM_SOURCE || "",
      utmCampaign: queryParams?.UTM_CAMPAIGN || "",
      utm_medium: queryParams?.UTM_MEDIUM || "",
      utm_term: queryParams?.UTM_TERM || "",
      click_id: queryParams?.CLICK_ID || "",
      NAME: data?.name,
      country_name: "",
      course_id: data?.course?.id,
      STATUS_ID: "NEW",
      OPENED: "Y",
      ASSIGNED_BY_ID: 1,
      PHONE: [
        {
          VALUE: data?.phone,
          VALUE_TYPE: "WORK",
        },
      ],
      EMAIL: [
        {
          VALUE: data?.email,
          VALUE_TYPE: "WORK",
        },
      ],
      COMMENTS: data?.message,
      SOURCE_ID: sourceId,
      UF_CRM_1584949782: "sw",
      UF_CRM_1584950014: "",
      params: {
        REGISTER_SONET_EVENT: "Y",
      },
    },
  });

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Cookie", "qmb=0.");

  try {
    const leadReq = await fetch(bitrix.leadUrl, {
      method: "POST",
      body: leadData,
      headers: myHeaders,
    });

    const leadRes = await leadReq.json();

    const lead_id = leadRes?.result;

    if (lead_id) {
      const rows = data?.course?.productId && [
        {
          PRODUCT_ID: data?.course?.productId,
          PRICE: data?.course?.price || data?.course?.discountPrice || 0,
          QUANTITY: 1,
        },
      ];

      const reqBody = JSON.stringify({
        id: lead_id,
        rows: rows,
      });

      const leadAddReq = await fetch(bitrix.leadAddUrl, {
        method: "POST",
        body: reqBody,
        headers: myHeaders,
      });

      const leadAddRes = await leadAddReq.json();

      return leadAddRes;
    }
  } catch (error) {
    console.error(error);
  }
};
