"use client";
import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { signOut, useSession } from "next-auth/react";
import dynamic from "next/dynamic";
import Image from "next/image";
import Link from "next/link";
import { useParams } from "next/navigation";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Navbar,
  NavbarBrand,
  NavbarMenu,
  NavbarMenuItem,
  NavbarMenuToggle,
  useDisclosure,
} from "@nextui-org/react";

// Lazy-load non-essential components
const FullPageModal = dynamic(() => import("../modal/FullPageModal"), {
  ssr: false,
});
const FullPageMobileModal = dynamic(
  () => import("../modal/FullPageMobileModal"),
  {
    ssr: false,
  }
);
const BookDemo = dynamic(() => import("../modal/BookDemo"), {
  ssr: false,
});

const url = process.env.NEXT_PUBLIC_WHATS_APP_LINK || "";

import { useDictionary } from "../../context/dictionaryProvider";
import CoursesAutocomplete from "../CoursesAutocomplete"; // Assume this component is created correctly
import { ContentContainer } from "../molecules"; // Assume this is a valid component
import { WhatsAppIcon } from "../molecules/WhatsAppIcon";
import { MenuIcon } from "../../assets/svg";
import { getHeaderMenus } from "../../actions/clientSide";
import { getImageUrlNew, getLink } from "../../lib/util";
import LpNewLogo from "../../../public/LpLogoNew.png";
import LangSwitcher from "./LangSwitcher";

export default function Header() {
  const dict = useDictionary();
  const params = useParams();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [data, setData] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(false);

  const menus = [
    { name: dict?.header?.corporateTraining, url: "/corporate-trainings" },
    { name: dict?.header?.testimonials, url: "/testimonial" },
  ];
  const mobileMenus = [
    { name: dict?.header?.blogMenu, url: "/blog" },
    { name: dict?.header?.aboutUs, url: "/about" },
    { name: dict?.header?.contactUs, url: "/contact" },
  ];

  const handleSearchTab = (e, tab) => {
    setShowSearchBar(true);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const {
    isOpen: isOpenTwo,
    onOpen: onOpenTwo,
    onOpenChange: onOpenChangeTwo,
  } = useDisclosure();

  const session = useSession();

  useEffect(() => {
    const fetchHeader = async () => {
      try {
        const response = await getHeaderMenus(params);
        setData(response);
      } catch (error) {
        console.log(error);
      }
    };
    fetchHeader();
  }, [params]);

  const handleLogout = async (e) => {
    e.preventDefault();
    await signOut();
  };

  const menuItems = [
    ...menus.map((menu) => ({
      type: "link",
      label: menu?.name,
      href: menu?.url,
    })),
    // { type: "link", label: "Log in", href: "/auth/login" },
    // { type: "link", label: "Register", href: "/auth/register" },
  ];

  const menuMobileItems = [
    ...mobileMenus.map((menu) => ({
      type: "link",
      label: menu?.name,
      href: menu?.url,
    })),
    // { type: "link", label: "Log in", href: "/auth/login" },
    // { type: "link", label: "Register", href: "/auth/register" },
  ];
  const CloseIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="#000"
        className=""
      >
        <path
          d="M18 6L6 18M6 6L18 18"
          stroke="#000"
          strokeWidth="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  };
  return (
    <div className="mb-24 lg:mb-40">
      <Navbar
        className="py-4 sm:pt-8 sm:pb-8 z-30 fixed top-0 left-0 w-full right-0"
        isMenuOpen={isMenuOpen}
        onMenuOpenChange={() => setIsMenuOpen(!isMenuOpen)}
        style={{ background: "#fff" }}
        classNames={{ wrapper: "px-0" }}
      >
        <ContentContainer className="flex items-center !gap-4 !sm:gap-16 justify-between">
          {/*========= Start Mobile Menu =========*/}
          <div className="flex justify-between items-center w-full lg:hidden gap-4">
            <NavbarBrand>
              <Link
                href={getLink(`/${params?.lang}`)}
                className="relative w-[60px]"
              >
                {data?.logo ? (
                  <Image
                    priority={true}
                    height={100}
                    width={100}
                    src={getImageUrlNew(data?.logo)}
                    alt="logo"
                  />
                ) : (
                  <Image
                    priority={true}
                    height={100}
                    width={100}
                    src={LpNewLogo}
                    alt="logo"
                  />
                )}
              </Link>
            </NavbarBrand>
            <Link
              href="#"
              onClick={(e) => {
                e.preventDefault();
                onOpen();
              }}
              className="!font-semibold flex items-center text-sm tracking-[0.42px] bg-[#0096ac] text-white py-3 px-4 rounded-full"
            >
              <span className="">{dict?.courses?.title}</span>
            </Link>
            <NavbarMenuToggle
              icon={<MenuIcon />}
              aria-label={isMenuOpen ? "Close menu" : "Open menu"}
              className={isMenuOpen ? "closeMenus" : "openMenus"}
            />
            <span onClick={() => handleSearchTab()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <circle
                  cx="10"
                  cy="10"
                  r="6"
                  stroke="black"
                  strokeWidth="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.5 14.5L19 19"
                  stroke="black"
                  strokeWidth="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            {showSearchBar && (
              <div className="absolute h-20 w-full left-0 bg-white flex items-center justify-between p-5 z-40">
                <CoursesAutocomplete />
                <span onClick={() => setShowSearchBar(false)} className="">
                  <CloseIcon />
                </span>
              </div>
            )}
            <div className="w-16">
              <LangSwitcher />
            </div>
          </div>
          {/*========= End Mobile Menu =========*/}

          {/*========= Start Desktop Menu =========*/}
          <ul className="hidden lg:flex gap-6 items-center">
            <NavbarBrand className="lg:me-2 w-[74px] 2xl:w-[100px]">
              <Link href={getLink(`/${params?.lang}`)}>
                {data?.logo ? (
                  <Image
                    priority={true}
                    height={100}
                    width={100}
                    src={getImageUrlNew(data?.logo)}
                    alt="logo"
                  />
                ) : (
                  <Image
                    priority={true}
                    height={100}
                    width={100}
                    src={LpNewLogo}
                    alt="logo"
                  />
                )}
              </Link>
            </NavbarBrand>
            <li>
              <Link
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  onOpen();
                }}
                className="!font-semibold flex items-center text-sm tracking-[0.42px] bg-[#0096ac] text-white py-3 px-4 rounded-full"
              >
                <span className="">{dict?.courses?.title}</span>
              </Link>
            </li>
          </ul>

          <ul className="hidden lg:flex gap-4 items-center">
            <StyledNavbarItem className="w-[300px] lg:w-[280px] xl:w-[320px]">
              <CoursesAutocomplete />
            </StyledNavbarItem>
          </ul>
          {/*========= End Desktop Menu =========*/}

          {/*========= Start Right Side Menus =========*/}
          <ul className="hidden lg:flex gap-3 items-center">
            {menus.map((menu, index) => (
              <StyledNavbarItem
                key={index}
                className="text-sm tracking-[0.42px] font-medium"
              >
                <Link href={getLink(`/${params?.lang}${menu?.url}`)}>
                  {menu?.name}
                </Link>
              </StyledNavbarItem>
            ))}
            {params?.slug && (
              <StyledNavbarItem>
                <Button
                  className="border border-black rounded-full bg-transparent text-black	font-semibold min-h-0 h-auto px-3 py-3 text-xs 2xl:text-sm"
                  onPress={onOpenTwo}
                >
                  {dict?.bookDemo}
                </Button>{" "}
              </StyledNavbarItem>
            )}
            <StyledNavbarItem className="w-16">
              <LangSwitcher />
            </StyledNavbarItem>
            <StyledNavbarItem>
              <Button
                className="border-0 bg-[#01DF62] text-white rounded-full max-w-12 min-w-12 lg:max-w-sm lg:min-w-sm min-h-0 h-auto px-4 py-2"
                onClick={() => window.open(url, "_blank")}
                href={url}
                id="whatsapp-button"
              >
                <WhatsAppIcon className="size-6 2xl:size-7 max-w-6 2xl:max-w-7" />{" "}
                <span className="hidden lg:block text-xs 2xl:text-sm">
                  {dict?.whatsApp}
                </span>
              </Button>
            </StyledNavbarItem>

            {/*=========  Profile Dropdown =========*/}
            <Dropdown placement="bottom-end">
              <StyledNavbarItem className="hidden sm:block">
                <DropdownTrigger>
                  {/* User Icon */}
                  <svg
                    width="35"
                    height="45"
                    viewBox="0 0 44 45"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.16797 37.1667V35.3333C9.16797 30.2707 13.272 26.1667 18.3346 26.1667H25.668C30.7306 26.1667 34.8346 30.2707 34.8346 35.3333V37.1667M29.3346 13.3333C29.3346 17.3834 26.0514 20.6667 22.0013 20.6667C17.9512 20.6667 14.668 17.3834 14.668 13.3333C14.668 9.28325 17.9512 6 22.0013 6C26.0514 6 29.3346 9.28325 29.3346 13.3333Z"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </DropdownTrigger>
              </StyledNavbarItem>
              <DropdownMenu aria-label="Profile Actions" variant="flat">
                {session.status !== "authenticated" && (
                  <DropdownItem key="login">
                    <Link href="/auth/login">{dict?.login}</Link>
                  </DropdownItem>
                )}
                {session.status !== "authenticated" && (
                  <DropdownItem key="register">
                    <Link href="/auth/register">{dict?.register}</Link>
                  </DropdownItem>
                )}
                {session.status === "authenticated" && (
                  <DropdownItem key="profile">
                    <Link href="/profile">{dict?.profileTitle}</Link>
                  </DropdownItem>
                )}
                {session.status === "authenticated" && (
                  <DropdownItem key="yourBookings">
                    <Link href="/bookings">{dict?.bookingTitle}</Link>
                  </DropdownItem>
                )}
                {session.status === "authenticated" && (
                  <DropdownItem key="yourBookings">
                    <button onClick={handleLogout}>logout</button>
                  </DropdownItem>
                )}
              </DropdownMenu>
            </Dropdown>
          </ul>
          {/*========= End Right Side Menus =========*/}

          {/*========= Start Mobile Menu =========*/}
          <NavbarMenu
            className={`pt-6 bg-[#fff] top-24 transition-all duration-500 ease-in-out z-[1101] ${
              isMenuOpen
                ? "w-full max-w-full !left-auto !top-0 right-0 bottom-auto fixed !h-full opacity-100 visible rounded-none shadow-[0_2px_7px_0px_rgba(0,0,0,0.3)]"
                : "w-0 opacity-0 invisible"
            }  `}
          >
            <span
              onClick={() => setIsMenuOpen(false)}
              className="flex relative justify-end ms-auto w-auto"
            >
              <CloseIcon />
            </span>
            {/* <NavbarMenuItem className="my-2">
              <Link
                href="#"
                className="w-full font-medium text-lg"
                onClick={(e) => {
                  e.preventDefault();
                  onOpen();
                  setIsMenuOpen(false);
                }}
              >
                {dict?.courses?.title}
              </Link>
            </NavbarMenuItem> */}
            {[...menuItems, ...menuMobileItems].map((item, index) => (
              <NavbarMenuItem key={index} className="my-2">
                {item.type === "link" ? (
                  <Link
                    className="w-full font-medium text-sm"
                    href={getLink(`/${params?.lang}${item?.href}`)}
                    onClick={() => setIsMenuOpen(false)}
                  >
                    {item.label}
                  </Link>
                ) : (
                  item.component
                )}
              </NavbarMenuItem>
            ))}
            <div className="mt-auto mb-8 flex flex-col gap-6">
              <NavbarMenuItem>
                <Button
                  className="border-0 bg-[#01DF62] text-white rounded-full w-full lg:min-w-sm min-h-0 h-auto px-5 py-[10px]"
                  onClick={() => window.open(url, "_blank")}
                  href={url}
                  id="whatsapp-button"
                >
                  <WhatsAppIcon className="size-6 2xl:size-7 max-w-6 2xl:max-w-7" />{" "}
                  <span className="text-xs 2xl:text-sm font-semibold">
                    {dict?.whatsApp}
                  </span>
                </Button>
              </NavbarMenuItem>
              <Link
                onClick={() => setIsMenuOpen(false)}
                href="/auth/login"
                className="bg-black text-white w-full block text-center rounded-full py-3 px-5 text-xs"
              >
                {dict?.login}
              </Link>
              {params?.slug && (
                <NavbarMenuItem>
                  <Button
                    className="border border-black rounded-full bg-transparent w-full text-black	font-semibold min-h-0 h-auto py-3 px-5 text-xs 2xl:text-sm"
                    onClick={() => {
                      setIsMenuOpen(false);
                      onOpenTwo();
                    }}
                  >
                    {dict?.bookDemo}
                  </Button>{" "}
                </NavbarMenuItem>
              )}
            </div>
          </NavbarMenu>
          {/*========= End Mobile Menu =========*/}
        </ContentContainer>
      </Navbar>

      {isOpen &&
        (isMobile ? (
          <FullPageMobileModal isOpen={isOpen} onOpenChange={onOpenChange} />
        ) : (
          <FullPageModal isOpen={isOpen} onOpenChange={onOpenChange} />
        ))}
      {isOpenTwo && (
        <BookDemo isOpen={isOpenTwo} onOpenChange={onOpenChangeTwo} />
      )}
    </div>
  );
}

const StyledNavbarItem = styled.li`
  position: relative;
  cursor: pointer;
`;
