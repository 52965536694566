"use client";
import React, { useState } from "react";
import { Select, SelectItem } from "@nextui-org/react";
import { useParams, usePathname, useRouter } from "next/navigation";
import {} from "next/navigation";

const LangSwitcher = () => {
  const { lang } = useParams();
  const pathName = usePathname();
  const router = useRouter();
  const [selectedLanguage, setSelectedLanguage] = useState(new Set([lang]));

  const handleLanguageChange = (locale) => {
    const newLocale = Array.from(locale)[0];
    setSelectedLanguage(locale);

    // Check if the pathname includes the current locale
    const newPath = pathName.startsWith(`/${lang}/`)
      ? pathName.replace(`/${lang}/`, `/${newLocale}/`) // Replace locale if it exists
      : pathName.startsWith(`/${lang}`)
      ? pathName.replace(`/${lang}`, `/${newLocale}`) // Replace locale if it's at the root
      : `/${newLocale}${pathName}`; // Prepend locale if not found

    // Push the new path with the new locale
    router.push(newPath, { locale: newLocale });
  };

  return (
    <Select
      size="sm"
      label={null}
      aria-label="language-switch"
      className="max-w-xs min-w-[75px] w-full "
      classNames={{
        value:
          "text-xs uppercase text-blue flex items-center gap-x-2 font-semibold tracking-[1.2px]",
        selectorIcon: "text-xs text-blue font-semibold",
        trigger: "border-none shadow-none bg-transparent hover:bg-transparent",
      }}
      selectedKeys={selectedLanguage}
      onSelectionChange={(key) => {
        handleLanguageChange(key);
      }}
    >
      <SelectItem key="en">EN</SelectItem>
      <SelectItem key="ar">AR</SelectItem>
    </Select>
  );
};

export default LangSwitcher;
