"use client"; // Marks this component as a client-side component
import React, { useState, useEffect } from "react";
import { ContentContainer, Layout, Stack } from "./molecules";
import DemandCarousel from "./DemandCarousel";
import { getTrendings } from "../actions/singles";
import { getRelatedCourse } from "../actions/clientSide";
import DemandCourseSkeleton from "./skeletons/DemandCourseSkeleton";
import { useDictionary } from "../context/dictionaryProvider";

const handleCourseFetching = async (
  key = "related",
  params,
  category,
  showRelated = false
) => {
  let onDemandCourses;
  switch (key) {
    case "related":
      onDemandCourses = await getRelatedCourse(
        { ...params, showRelated },
        category?.slug ?? ""
      );
      break;
    case "trending":
      onDemandCourses = await getTrendings(params);
      break;
    default:
      onDemandCourses = [];
  }
  return onDemandCourses;
};

const DemandCourses = ({ title, by, params, category, showRelated }) => {
  const [courses, setCourses] = useState(null); // Initially null to detect loading state
  const [loading, setLoading] = useState(true); // New loading state
  const dict = useDictionary();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await handleCourseFetching(
          by,
          params,
          category,
          showRelated
        );
        setCourses(response?.data || []);
      } catch (error) {
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchData();
  }, [by, params, category, showRelated]);

  return (
    <>
      {loading ? (
        <Layout className="demandCourses">
          <ContentContainer>
            <Stack>
              {/* Skeleton loader when courses are still being fetched */}
              <h3 className="text-center w-full mb-11 text-[32px] leading-[32px] md:text-[40px] md:leading-[48px] font-extrabold">
                {title || "Loading..."}
              </h3>
              {/* Display skeletons as placeholders */}
              <div className="flex flex-wrap gap-6">
                {/* Render multiple skeletons to match the number of cards you'd expect */}
                {[...Array(3)].map((_, i) => (
                  <DemandCourseSkeleton key={i} />
                ))}
              </div>
            </Stack>
          </ContentContainer>
        </Layout>
      ) : (
        <>
          {courses.length > 0 && (
            <Layout className="demandCourses">
              <ContentContainer>
                <Stack>
                  <h3 className="text-center w-full mb-11 text-[32px] leading-[32px] md:text-[40px] md:leading-[48px] font-extrabold">
                    {title || dict?.demandCourses?.title}
                  </h3>
                  <DemandCarousel courses={courses} />
                </Stack>
              </ContentContainer>
            </Layout>
          )}
        </>
      )}
    </>
  );
};

export default DemandCourses;
